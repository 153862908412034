import React, { useRef, useEffect, useCallback } from 'react';

const NOOP = () => undefined;
const compose = (ComposedComponent) => {
  return React.forwardRef(function ClickOutside(props, forwaredRef) {

    const ref = useRef();

    let handlerFunc = NOOP;

    const onClickOutside = (func) => handlerFunc = func;

    const handleClick = useCallback((e) => {

      if (ref && ref.current && !ref.current.contains(e.target)) {
        handlerFunc(false)
      }
    }, [ref, handlerFunc])

    useEffect(() => {
      document.addEventListener('mousedown', handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [handleClick])

    return (
      <ComposedComponent {...props} ref={forwaredRef} onClickOutside={onClickOutside} clickOutsideNode={ref} />
    )
  });

}
export default compose;

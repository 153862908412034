import React, { useState, useRef } from 'react';
import './UserInput.scss';
import ClickOutside from '../ClickOutside';
import ChevronIcon from '../../icons/ChevronIcon';

const NOOP = () => undefined
const Comp = ({
  options,
  onChange = NOOP,
  defaultValue,
  label,
  name,
  type,
  style = {},
  onClickOutside,
  error,
  placeholder,
  clickOutsideNode,
  maxHeight,
  searchable,
  disabled
}, ref) => {

  const TYPE = type || (options ? 'select' : 'text');
  const inputRef = useRef();

  const [expanded, _setExpanded] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const setExpanded = (val) => {
    if (val && inputRef.current) {
      inputRef.current.focus()
    }
    _setExpanded(val)
  }

  const onChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  if (TYPE === 'select') {
    onClickOutside(() => setExpanded(false))
  }
  
  let selectedOption;
  
  if (options) {
    selectedOption = options.find(o => o.name === selectedValue || o.label === selectedValue); 
  }

  const maxHeightCalc = expanded ? (maxHeight || (options.length+1) * 50) : 0
  
  return (
    <div className={`UserInput ${disabled ? 'disabled' : ''}`} style={style} ref={clickOutsideNode}>
    
      {
        label &&
        <p className='label'>{label}</p>
      }

      <div className={`main-option ${expanded ? 'expanded' : ''} ${TYPE} ${error ? 'user-error' : ''}`} onClick={() => TYPE === 'select' ? setExpanded(!expanded) : NOOP}>

        {
          (TYPE === 'text' || TYPE === 'number') &&
          <input ref={ref} placeholder={placeholder} type={type || 'text'} name={name || label} onChange={(e) => onChange(e.target.value)} defaultValue={defaultValue} />
        }  

        {
          TYPE === 'textarea' &&
          <textarea name={name || label} onChange={(e) => onChange(e.target.value)} defaultValue={defaultValue}  />
        }
       

        {
          TYPE === 'select' &&
          <>
            <p className={selectedOption ? '' : 'placeholder'}>
              {selectedOption ? (selectedOption.label || selectedOption.name) : placeholder || "Select an option"}
            </p>
            <div className='chevron-wrapper'>
              <ChevronIcon fill='gray.800' width='14px' height='14px' marginTop='4px' />
            </div>
          </>
        }
          
      </div>
      {
        TYPE === 'select' &&
        <div style={{ maxHeight: maxHeightCalc + 'px' }} className={`options ${expanded ? 'expanded' : ''} ${label ? 'hasLabel' : ''}`}>
          
          <div className={`option placeholder ${searchable ? 'searchable' : ''}`} key={`${name}-${label}`} onClick={() => searchable ? () => undefined : setExpanded(false)}>

            {
              searchable ?
                <input ref={inputRef} onChange={onChangeSearch} style={{ width: '100%', height: '100%' }} type='text' placeholder='Search' />
                : placeholder || "Select an option"
            }
            
          </div>

          <div className='other-options' style={{  maxHeight : Math.min(maxHeightCalc - 50, 250) + 'px' }}>
            {
              options.filter(o => {
                if (searchText === '') return true;
                return (o.label || o.name).toLowerCase().indexOf(searchText.toLowerCase()) > -1
              }).map(option => {

                const { name, label } = option;

                const onClick = () => {
                  setSelectedValue(name);
                  setExpanded(false);

                  if (onChange) {
                    onChange(name);
                  }
                }

                return (
                  <div className={`option`} onClick={onClick} key={`${name}-${label}`}>
                    {label || name}
                  </div>
                )

              })
            }
          </div>
            
        


        </div>

      }

      {
        error && typeof error === 'string' &&
        <p className='error-string'>{error}</p>
      }
      
    
    </div>
  )

}

export default ClickOutside(React.forwardRef(Comp));